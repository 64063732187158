import React from 'react';

const Dashboard = () => {
  return (
    <div className="wrapper">
      {/* Your dashboard content goes here */}
    </div>
  );
};

export default Dashboard;